/*
function Enquire() {
    enquire
        .register("screen and (min-width: 320px)", {
            match : function() {
                nav();
                subNavTigger();
            }
        })
        .register("screen and (max-width: 1135px)", {
            unmatch: function () {
                nav();
                subNavTigger();
            }
        })
        .register("screen and (min-width: 1135px)", {
            unmatch: function () {
                nav();
                subNavTigger();
            }
        });
}
*/

function mobileNav() {
    $('#mobile-nav-trigger').on('click', function(event){
        event.preventDefault();
        $(this).toggleClass('is-clicked');
        $('#main-nav').toggleClass('open');
        $('body').toggleClass('overflow-hidden');
    });
}

function formSwitch() {
    $('.radioBegin').on('change', function(){
        if ( $('#begin2').prop('checked') ) {
            $('#beginWhen').attr({
                disabled: false,
                required: 'required',
                placeholder: 'Datum eintragen *'
            });
        } else {
            $('#beginWhen').attr({
                disabled: 'disabled',
                required: false,
                placeholder: 'Datum eintragen'
            });
        }
    });
    $('.radioKind').on('change', function(){
        if ( $('#kind2').prop('checked') ) {
            $('#kindHours').attr({
                disabled: false,
                required: 'required',
                placeholder: 'X Stunden *'
            });
        } else {
            $('#kindHours').attr({
                disabled: 'disabled',
                required: false,
                placeholder: 'X Stunden'
            });
        }
    });
    $('input[type=file]').on('change', function(){
        var filename = $(this).val().split('\\').pop();
        $(this).siblings('label').children('span').text(filename);
    });
    $('#acceptPrivacy').on('change', function(){
        if ( $(this).prop('checked') ) {
            $('input[type=submit]').attr({
                disabled: false
            });
        } else {
            $('input[type=submit]').attr({
                disabled: true
            });
        }
    });
}

function map() {
    jQuery('.map').maps({
        center : {                        
            lat: 51.4489836,
            lng: 8.862114
        },
        zoom: 14,
        marker: {                           
            icon: {                         
                path: '/wp-content/themes/rekruto/assets/img/marker.png',
                w: 51,
                h: 70
            }
        },
        scrollWheel: false,                        
        customStyle: [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#052366"
                    },
                    {
                        "saturation": "-70"
                    },
                    {
                        "lightness": "85"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "lightness": "-53"
                    },
                    {
                        "weight": "1.00"
                    },
                    {
                        "gamma": "0.98"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "saturation": "-18"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#57677a"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ]
    });
}

function disableRightClick() {
    jQuery( 'img' ).bind( 'contextmenu' , function(e) {
        return false;
    });
}

jQuery(document).ready(function() {
    // Enquire();
    mobileNav();
    map();
    formSwitch();
    disableRightClick();
});